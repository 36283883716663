var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq-create-page"},[_c('div',{staticClass:"heading-text fw-500 d-flex justify-space-between flex-wrap align-center mt-5 mb-8"},[_vm._m(0),_c('v-btn',{staticClass:"text-7d transparent",attrs:{"to":{ name: 'FAQs' },"text":""}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"fill":"#7d7d7d"},attrs:{"size":"17"}},[_vm._v(" $PreviousAngleCircle ")]),_vm._v(" 戻る ")],1)],1),_c('v-card',{staticClass:"pa-5 rounded-sm py-5"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-container',{staticClass:"container-main py-4"},[_c('v-row',[_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t(_vm.fields.user_type.name),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"row":""},model:{value:(_vm.fields.user_type.value),callback:function ($$v) {_vm.$set(_vm.fields.user_type, "value", $$v)},expression:"fields.user_type.value"}},[_c('v-radio',{attrs:{"label":"ユーザー","value":"2","color":"red"}}),_c('v-radio',{attrs:{"label":"企業","value":"1","color":"red"}})],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("カテゴリ")])]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.fields.faq_category_id.name),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","dense":"","placeholder":"カテゴリを選択してください","item-text":"name","item-value":"id","items":_vm.getFaqCategories,"menu-props":"auto","color":"grey"},model:{value:(_vm.fields.faq_category_id.value),callback:function ($$v) {_vm.$set(_vm.fields.faq_category_id, "value", $$v)},expression:"fields.faq_category_id.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("質問")])]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.fields.question.name),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","dense":"","auto-grow":"","rows":"3","placeholder":"質問内容を入力してください"},model:{value:(_vm.fields.question.value),callback:function ($$v) {_vm.$set(_vm.fields.question, "value", $$v)},expression:"fields.question.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"d-block font-14px"},[_c('span',[_vm._v("回答")])]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.fields.answer.name),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"outlined":"","dense":"","auto-grow":"","rows":"5","placeholder":"質問に対する回答を入力してください"},model:{value:(_vm.fields.answer.value),callback:function ($$v) {_vm.$set(_vm.fields.answer, "value", $$v)},expression:"fields.answer.value"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t(_vm.fields.is_release.name),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"error-messages":errors,"error":errors.length !== 0,"hide-details":errors.length <= 0,"label":"公開","color":"red","true-value":"1","false-value":"0"},model:{value:(_vm.fields.is_release.value),callback:function ($$v) {_vm.$set(_vm.fields.is_release, "value", $$v)},expression:"fields.is_release.value"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-center mt-10"},[_c('v-btn',{staticClass:"white border-red text-red mr-5",attrs:{"type":"button","max-width":"188px","width":"100%","height":"35","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.setDraftPost()}}},[_vm._v(" 下書きとして保存 ")]),_c('v-btn',{staticClass:"bg-red white--text",attrs:{"type":"submit","disabled":invalid,"loading":_vm.loading,"max-width":"188px","width":"100%","height":"35"}},[_vm._v("更新する")])],1)])],1)],1)],1)]}}])})],1),_c('SuccessModel',{attrs:{"text":"企業情報を登録しました。","buttonText":"企業一覧へ戻る","routeName":"FAQs","dialog":_vm.dialog.success},on:{"closeModel":function($event){_vm.dialog.success = false}}}),_c('SimpleModel',{attrs:{"text":_vm.dialogText,"dialog":_vm.dialog.common,"loading":_vm.loading},on:{"submitSuccess":function($event){return _vm.deleteCorporate()},"closeModel":function($event){_vm.dialog.common = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"font-24px mb-1 mr-4"},[_c('span',{staticClass:"fw-500"},[_vm._v("FAQ管理")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"font-20px fw-400"},[_vm._v("FAQ編集")])])}]

export { render, staticRenderFns }